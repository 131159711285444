import i18next, { type TFunction } from 'i18next';

export const AT = i18next.createInstance({
  nsSeparator: '.',
  keySeparator: ' #### ',
  fallbackLng: 'kr',
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    console.warn(lng, ns, key, fallbackValue);
  },
});

export const T: TFunction = AT.t;
export let LANG: 'kr' | 'en' | 'jp';

export const initialize = async (lang?: string) => {
  if (!AT.isInitialized) {
    await AT.init({
      fallbackLng: 'kr',
      resources: {
        en: (await import('./resources/en')).default,
        kr: (await import('./resources/kr')).default,
        jp: (await import('./resources/jp')).default,
      },
    });
  }

  if (typeof window === 'undefined') {
    const lng = lang ?? process.env.LANG ?? 'kr';
    await AT.changeLanguage(lng);
  } else {
    const lng = lang ?? document.querySelector('html')?.lang ?? 'kr';
    await AT.changeLanguage(lng);
  }

  LANG = AT.language as typeof LANG;
};
