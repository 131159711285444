import { fromEntries, omitBy } from '@fxts/core';
import { makeQueryString } from './makeQueryString';

const RESIZE_IMAGE_URL = 'common.image.cf.marpple.co';

const is_gif = (url: string) => /.+\.gif$/.test(url);
const addProtocol = (url: string) => ([/^http/, /^https/].some((re) => re.test(url)) ? url : 'https:' + url);

/** @description Constructs a URL with specified resizing options.*/
interface GetResizedImageUrlParams {
  /**  The URL of the image to resize. */
  url: string;
  /**
   * The quality of the resized image (1-100)
   * */
  quality?: number;
  /**
   * The desired height of the image
   * */
  height?: number;
  /**
   * The desired width of the image.
   * */
  width?: number;
  /**
   * The desired format of the output image (e.g., 'jpg', 'png').
   * @default 'webp'
   * */
  format?: string;
  /**
   * The background color, applicable if the image has transparency.
   * */
  bg?: string;
  /**
   * ???
   * */
  r?: string;
  is_ie?: boolean;
}

export const resizeS3Image = ({ url, quality, height, width, format, bg, r, is_ie }: GetResizedImageUrlParams) => {
  if (!url || is_gif(url)) return url || '';
  const url_obj = new URL(addProtocol(url));
  const f = is_ie && format === 'webp' ? '' : format;
  const query_string = makeQueryString({ q: quality, h: height, w: width, f: format, bg, r });
  return `https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${query_string}`;
};

export const updateResizeS3Image = ({ url, quality, height, width, format, bg, is_ie }: GetResizedImageUrlParams) => {
  if (!url || is_gif(url)) return url;
  const url_obj = new URL(addProtocol(url));
  const f = is_ie && format === 'webp' ? '' : format;
  const params = {
    ...fromEntries(url_obj.searchParams.entries()),
    ...omitBy(([_, v]) => !v, { q: quality, h: height, w: width, f, bg }),
  };
  const query_string = makeQueryString(params);

  return `https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${query_string}`;
};
