import { html, Page, View } from 'rune-ts';
import type { HtmlOption } from '../type/storybook';

const applyStyleToElement = (style: Partial<CSSStyleDeclaration>, element: HTMLElement) => {
  for (const property of Object.keys(style)) {
    element.style[property as any] = style[property];
  }

  return element;
};

export const applyStyleWrapper: (style: Partial<CSSStyleDeclaration>, story_element: HTMLElement) => HTMLElement = (
  style,
  story_element,
) => {
  const container_element = document.createElement('div');
  container_element.classList.add('story_wrapper');

  applyStyleToElement(style, container_element);

  // Append the story element to the container
  if (typeof story_element === 'string') {
    container_element.innerHTML = story_element;
  } else {
    container_element.appendChild(story_element);
  }

  return container_element;
};

export const applyBaseSetting = (
  option: HtmlOption,
  story_element: View | Page<object>,
  style?: Partial<CSSStyleDeclaration>,
) => {
  makeHtml(option);

  return applyStyleWrapper(
    story_element instanceof Page
      ? {
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }
      : {
          backgroundColor: '#eee',
          padding: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        },
    renderView(story_element, option, style),
  );
};

export const makeHtml = (option: HtmlOption) => {
  const html_el = document.querySelector('html');

  if (!html_el) return;
  html_el.setAttribute('device', option.device ?? 'pc');
  html_el.setAttribute('device_type', option.device_type ?? 'pc');
  html_el.setAttribute('theme', option.theme);
  html_el.setAttribute('lang', option.lang);
  return option;
};

class StorybookPage extends Page<View> {
  override template() {
    return html` <div class="run-page-warpper">${this.data}</div> `;
  }
}

export const renderView = (view: View | Page<object>, sharedData: HtmlOption, style?: Partial<CSSStyleDeclaration>) => {
  const el = view instanceof Page ? view.render() : new StorybookPage(view, sharedData).render();
  const _style =
    view instanceof Page
      ? {
          width: '100%',
          height: '100%',
        }
      : {
          backgroundColor: sharedData.theme === 'Ciety' ? '#13131a' : '#fff',
          width: sharedData.device === 'mo' ? '375px' : '100%',
          transform: 'scale(1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };

  applyStyleToElement({ ..._style, ...style }, el);

  if (view instanceof Page) {
    const body_el = document.querySelector('body');

    applyStyleToElement({ padding: '0' }, body_el!);
  }

  return el;
};
